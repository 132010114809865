import axios from "axios";
import { MessageBox, Message } from "element-ui";
// import store from "@/store";
import { getToken, removeToken } from "@/utils/auth";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 6000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    const hasToken = getToken();
    if (hasToken) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Token"] = hasToken;
    }
    return config;
  },
  (error) => {
    // do something with request error
    // // //console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.statusCode !== 200) {
      // Message({
      //   message: res.statusMsg || "Error",
      //   type: "error",
      //   duration: 5 * 1000,
      // });
      // 与后端约定statusCode值做状态判定处理
      if (res.statusCode === 506) {
        removeToken();
        // to re-login
        // MessageBox.confirm("登录已过期,请重新登录", {
        //   confirmButtonText: "重新登录",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // }).then(() => {
        //   // 清除用户ID
        //   this.$cookie.set("userId", "", { expires: "-1" });
        //   // 清除用户名
        //   this.$store.dispatch("saveUserName", "");
        //   // 清空购物车数量
        //   this.$store.dispatch("saveCartCount", 0);
        //   // 清除token    
        //   removeToken();
        //   this.$router.push("/login");
        //   location.reload();
        // });
      }
      // return Promise.reject(new Error(res.statusMsg || 'Error'))
    } else {
      return res;
    }
  },
  (error) => {
    // //console.log("err" + error); // for debug
    Message({
      message: error.statusMsg,
      type: "error",
      duration: 6 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
